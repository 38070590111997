<template>
    <div class="container common">
      <div class="includes">
          <div class="row">
            <div class="cols-3">
              <p class="descr">
                Amenities
              </p>
            </div>
              <div class="cols-7 ">
                <p class="descr full-view">
                  Amenities
                </p>
                <div ref="toplst"></div>
                <transition-group name="slide-fade">
                  <div v-for="(item, index) in pack_one"
                       class="itm-wrap"

                       :class="item === 'Indoor lap pool and pool deck with lounge seating and overhead skylight' ? 'additionaly' : '' "
                       :key="index">
                    <div v-if="item === 'Indoor lap pool and pool deck with lounge seating and overhead skylight'" class="itm-add-name">Additional fees apply:</div>
                    <div class="itm-text">
                      <p class="itm-name" v-html="item"></p>
                    </div>
                    <img :src="listImg" alt="" class="itm-img">
                  </div>
                </transition-group>
                  <div v-if="length===5" @click="length=20" class="show-btn">Show more</div>
                  <div v-else @click="ShowLess()" class="show-btn">Show less</div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import listImg from "@/assets/brookfield_web/icn_checkbox.svg"

export default {
    data () {
      return {
        windowWidth: window.innerWidth,
        length:5,
        length2:5,
        listImg,
        packageList1: [
          'Expansive roof deck with grilling areas and private seating',
          'Club-quality, two-story fitness and wellness center',
          'Top-of-the-line cardio equipment',
          'Strength training equipment, with free weights and machines',
          'Studio for yoga, Pilates, and HIIT',
          'Resident lounge, with indoor–outdoor terrarium and coffee bar',
          'Indoor basketball court',
          'Game room and media lounge',
          'Co-working space with WiFiTM and robust seating',
          'Greenscaped central courtyard',
          'Indoor lap pool and pool deck with lounge seating and overhead skylight',
          'Reservable event space with kitchen, bar, and social lounge',
          'Indoor bike storage',
          'Valet parking, on-site parking, and electric vehicle charging stations',
          'Package delivery system, including cold storage',
          'Pet care',
    ],

      }
    },
  methods:{
    ShowLess(){
      this.$refs["toplst"].scrollIntoView({ behavior: "smooth" })
      this.length=5
    }
  },
  computed:{
    pack_one: function () {
      let list = this.packageList1
      return list.filter((item, index)=> index < this.length)
    },

  }
}
</script>

<style scoped >
.full-view{
  display: none;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(40px);
  opacity: 0;
}
.show-btn{
  cursor: pointer;
  margin-top: 25px;
  height: 28px;
  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.additionaly{
  margin-top: 52px;
  position: relative;
}
.itm-text{
  display: flex;
  flex-direction: column;
  align-self: center;
  width: auto;
}
.itm-name{
  color: #1B2023;
  font-family: "Helvetica Neue Regular";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.active{
  margin: 140px 0 80px;
  font-family: "Helvetica Neue Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 46px;
  color: #1B2023;
}
.itm-add-name{
  position: absolute;
  top: -22px;
  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.itm-img{
  height: 15px;
  width: 20px;
}
.itm-wrap{
  padding: 26px 0;
  border-bottom: 1px solid #1B2023;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.descr{
  height: 28px;
  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.row{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.cols-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 31%;
  flex: 0 0 31%;
  max-width: 31%;
  padding: 26px 0;
}.cols-7 {
  -webkit-box-flex: 0;
   -ms-flex: 0 0 69%;
   flex: 0 0 69%;
   max-width: 69%;
}
 .common{
   width: auto;
   padding: 24px;
   margin: auto
 }
.includes{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 54px;

}
.includes__title{
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
}
.includes__wrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.includes__list{
    color: var(--text-color);
    font-family: "Helvetica Neue Regular";
    font-size: 14px;
    line-height: 25px;
}
.includes__item{
    margin-bottom: 4px;
  width: 230px
}

@media screen and (max-width: 1100px) {
  .full-view{
    display: block;
  }
    .includes{
        padding-top: 12px;
        margin: -40px 0px 120px 0px;
    }
  .cols-3 {
    display: none;
  }
  .cols-7 {
     -webkit-box-flex: 0;
     -ms-flex: 0 0 100%;
     flex: 0 0 100%;
     max-width: 100%;
   }
  .descr{
    margin-top: 32px;
  }

}

@media screen and (max-width: 950px) {
    .includes__wrapper{
        -ms-flex-preferred-size: 60%;
        flex-basis: 60%;
    }
    li{
        margin-left: 30px;
    }
}
@media screen and (max-width: 800px) {
    .includes__wrapper{
        -ms-flex-preferred-size: 70%;
        flex-basis: 70%;
    }
}
@media screen and (max-width: 768px) {
  .full-view{
    display: block;
  }
  .active{
    padding: 0 24px;
    margin: 100px 0 64px;
    font-size: 29px;
    line-height: 39px;
    color: #1B2023;
  }
  .common{
    width: auto;
    margin: 32px auto 0;
    padding: 0;
  }
  .includes{
    padding: 0 24px;
    margin: 28px 0px 120px 0px;
  }
  .descr{
    margin-top: 32px;
  }

}
@media screen and (max-width: 600px) {
  .includes{
    padding: 0 16px;
    margin: 28px 0px 120px 0px;
  }
  .itm-add-name{
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    line-height: 25px;
  }
    .includes[data-v-17b1069e] {
      margin-bottom: 10px;
      flex-direction: column;
    }
  .itm-text{
    width: 315px;
  }
    .show-btn{
      font-size: 14px;
      line-height: 25px;
    }
    .itm-name{
      font-size: 14px;
      line-height: 25px;
    }
    .descr{
      margin-top: 20px;
      font-size: 14px;
      line-height: 25px;
    }
    .itm-wrap{
      padding: 10px 0;
      border-bottom: 1px solid #1B2023;
      justify-content: space-between;
      display: flex;
      align-items: center;
    }
    .includes{
        margin: 0px 0px 80px 0px;
        flex-direction: column;
    }
    .includes__wrapper{
        flex-direction: column;
    }
    .includes__title{
        margin-bottom: 12px;
    }
    li{
        margin-left: 20px;
    }
}
@media screen and (max-width: 350px) {
    .includes{
        margin-bottom: 56px;
    }
  .itm-text{
    width: 255px;
  }
}
</style>