<template>
  <div  @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        class="mod"
  >
    <modal
        name="MenuModal"
        :adaptive="true"
        width="100%"
        height="100%"
        @opened="openModal"
        @closed="resetModal"
        class="menu-sld"
        :class="modal_opened ? 'open-mdls' : ''"
    >
      <div class="menu-modal" >
        <img  src="@/assets/brookfield_web/logo.svg" class="menu__logo">
        <span class="menu__close" @click="hideModal">
          <span
              class="icon-icn_menu_close1"
          ></span>
       </span>
      </div>

      <div class="mobileMenu"   >
      <div class="mobileMenu__wrapper">
        <a class="mobileMenu__item"
           v-for="navItem in this.navItems" v-bind:key="navItem"
           :href="`#${navItem}`"
           @click="hideModal"
        >{{navItem}}</a>
      </div>
    </div>

    </modal>
      </div>
</template>

<script>
export default {
name: "MenuModal",
  data () {
    return {
      show_menu: false,
      isBurgerHover: false,
      windowWidth: window.innerWidth,
      scrollY: window.scrollY,
      BurgerShowBrakepoint: 900,
      isMobileMenuOpened: false,
      modal_opened: false,
      menuTranslate: 'translateY(-1300px)',
      burgerClickCounter: 0, //one click on burger send two events,
    }
  },
  methods:{
    openModal(){
      this.modal_opened = true
    },
    resetModal(){},
    hideModal(){
      this.modal_opened = false
      this.$modal.hide('MenuModal');
    },

  },
  props: {
    title: String,
    subtitle: String,
    navItems: Array
  },
}
</script>

<style >
.menu-sld{
  top:-50px
}
.menu-modal{
  padding: 24px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Helvetica Neue Medium';
  border-bottom: 1px solid rgba(255,255,255,0.4);
  z-index: 10;
  position: relative;
}
.icon-icn_menu_close1:before {
  content: "\e902";
  color: #fff;
  font-size: 20px;
}
.menu__close{
  background: transparent;
  border: none;
  cursor: pointer;
  -ms-flex-item-align: end;
  align-self: flex-end;

}
.mobileMenu{
  background-color: var(--primary-color);
  z-index: 2;
  height: 100vh;
  padding-top: 100px;

}
.mobileMenu__wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mobileMenu__item{
  font-family: 'Helvetica Neue Bold';
  color: var(--secondary-color);
  font-size: 56px;
  font-weight: bold;
  line-height: 69px;
  text-decoration: none;
  margin-bottom: 48px;
}
@media screen and (max-width: 600px) {

  .mobileMenu__item{
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 40px;
  }

}
@media screen and (max-width: 420px) {

  .mobileMenu__item{
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 58px;
  }
}

</style>