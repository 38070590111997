<template>
    <div class="container description">
        <div v-if="sectionName" class="chapter-title-added">{{sectionName}}</div>
        <p v-if="header" class="aptmnts" :class="header_style"> {{header}}</p>
        <a v-if="showLink" href="https://www.lincolnbankside.com/wp-content/uploads/2023/07/Bankside_Neighborhood_Map.pdf.pdf?x91838"
           class="btn link-btn"
           target="_blank"><span>View Neighborhood Map</span></a>
        <p class="description__text">{{firstText}}</p>
        <p v-if="secondText && secondText.length" class="description__text">{{secondText}}</p>
        <p v-if="underlinedText && underlinedText.length" class="description__text underline">{{underlinedText}}</p>
    </div>
</template>

<script>

export default {
    data () {
      return {

      }
    },
    props: {
        header: {
          type: String
        },
       header_style: {
          type: String
        },
        firstText: {
            type: String
        },
        secondText: {
            type: String
        },
        underlinedText: {
            type: String
        },
        showLink: {
            type: Boolean
        },
       sectionName: {
            type: String
        },

    }
}
</script>


<style scoped>
.narrow{
  width: 650px;
}
.wide{
  width: auto;
}
.link-btn{
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 276px;
  text-decoration: none;
  margin: 0 auto 25px;
}
.link-btn > span{
  height: 15px;
}
.aptmnts{

  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 46px;
  margin-bottom: 80px;
}
.description{
    margin-bottom: 40px;
    padding: 80px 100px 0;
}
.description__text{
    color: var(--text-color);
    font-family: "Helvetica Neue Regular";
    font-size: 34px;
    font-weight: 500;
    line-height: 46px;
    margin-bottom: 32px;
}
.underline{
    font-family: "Helvetica Neue Regular";
    text-decoration: underline;
}
@media screen and (max-width: 1100px) {
    .aptmnts{
      font-family: "Helvetica Neue Regular";
      font-size: 29px;
      line-height: 39px;
      margin-bottom: 56px;
    }
    .description{
        margin-bottom: 64px;
        padding: 94px 0 0;
    }
    .description__text{
        font-size: 29px;
        line-height: 39px;
      margin-bottom: 28px;
    }
}

@media screen and (max-width: 768px) {
  .wide{
    width: 467px;
  }
  .description{
    margin-top: 64px;
    margin-bottom: 0
  }
  .description__text:last-of-type{
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
    .narrow{
      width: 95%
    }
    .wide{
      width: 95%;
    }
    .aptmnts{
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 48px;
    }
    .description{
        margin-top: 64px;
        margin-bottom: 48px;
    }
    .description__text{
        font-size: 24px;
        line-height: 31px;
    }
}

@media screen and (max-width: 350px) {
    .description{
        margin-top: 48px;
        margin-bottom: 48px;
    }
    .description__text{
        font-size: 21px;
        line-height: 29px;
        margin-bottom: 24px;
    }
}
</style>
