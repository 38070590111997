<template>
  <div class="switch" @click="$emit('toggle')">
    <div class="line">
      <div class="dot" :class="active ? 'active-dot' : ''"></div>
    </div>
  </div>
</template>

<script>
export default {
name: "Switcher",
  props: {
    active: {
      type: Boolean
    },
  }
}
</script>

<style scoped>
.switch{
  position: relative;
  cursor: pointer;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
}
.line{
  height: 10px;
  width: 36px;
  background-color: #FFFFFF;
}
.dot{
  height: 24px;
  width: 24px;
  background-color: #FFFFFF;
  position: absolute;
  border-radius: 50%;
  top: -7px;
  left: -8px;
  transition: 0.5s;
}
.active-dot{
  background-color: #FFC869;
  left: 21px;

}
</style>