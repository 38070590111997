<template>
  <div>
    <transition v-if="show_sign" name="fade">
      <div >
        <div class="info">
          <div class="wrapper">
            <div class="texts">
              <p class="title">
                Bankside Properties Cookie Policy
              </p>
              <p class="descr">
                This site uses cookies, including third-party cookies, to enable website functionality, analyze the performance
                of our site and our advertising, and personalize and target content and ads. By clicking “Accept Cookies,”
                you agree to this use of cookies. You can manage your preferences by clicking Cookie Settings.
              </p>
            </div>
            <div class="btns">
                <span @click="$modal.show('CookieModal');" class="cookie-settings">
                    Cookie Settings
                </span>
              <button class="btn menu__btn"
                      @click="setCookie()"
              ><span  class="sign-text">Accept</span></button>
            </div>
          </div>
        </div>
        <div class="overlay"></div>
      </div>
    </transition>
    <modal
        name="CookieModal"
        classes="CookieModal"
        :adaptive="true"
        @opened="openedModal"
        @closed="closedModal"
    >
      <div class="headr">
        <div class="modal__close" @click="hideModal">
          <div class="cls-btn"> </div>
        </div>
        <p class="pps">
          Privacy Preference Settings
        </p>
      </div>
      <div class="wrap">
        <div class="">
          <p class="title-descr">
            Your Privacy
          </p>
          <p class="descr">
            When you visit any website, it may store or retrieve information on your browser, often in the form of cookies. The
            information collected might be about or relate to you, your preferences or your device. This information can be used
            to give you a more personalized web experience. You can choose not to allow certain types of cookies. Click on the
            different category headings to find out more and change our default settings. Blocking some types of cookies may
            impact your experience and certain features on the site. To opt-out of our cookie-based tracking for targeted advertising
            purposes, turn off “Personalized Experience Cookies” below. Your selection is specific to the device, website, and
            browser you are using, and is deleted whenever you clear your browser’s cache. If you are a California resident and
            would like personal information about you to be excluded from other data transfers that may be considered “sales”
            under California law, please follow the instructions in our <span>Privacy Policy.</span>
          </p>
          <hr class="devider">
          <div class="flex-descr colmn">
            <p class="title-descr ">
              Strictly Necessary Cookies
            </p>
            <p class="title-descr yellow">
              Always Active
            </p>
          </div>
          <p class="descr">
            These cookies are necessary for the website to function and cannot be switched off in our systems. You can set your
            browser to block or alert you about these cookies, but some parts of the site will not then work.
          </p>
          <hr class="devider">
          <div class="flex-descr">
            <p class="title-descr">
              Personal Experience
            </p>
            <p class="title-descr">
              <Switcher :active="pers_exp" @toggle="pers_exp = !pers_exp"></Switcher>
            </p>
          </div>
          <p class="descr">
            You can opt-out of our cookie-based tracking for advertising purposes by using this toggle switch. You can also make
            more specific choices about the use of cookies on this site below. Your selections are specific to the device, website,
            and browser you are using, and is deleted whenever you clear your browser’s cache. If you are a California and would
            like personal information about you to be excluded from other data transfers that may be considered “sales” under
            California law, please follow the instructions in our Privacy Policy.
          </p>
          <hr class="devider">
          <p class="title-descr">
            Targeting cookies
          </p>
          <p class="descr mb">
            These cookies may be set on our site by our advertising partners. They may be used by those companies to build
            a profile of your interests and show you relevant adverts on other sites. If you do not allow these cookies, not all
            advertising will stop, but you will experience less targeted advertising.
          </p>
        </div>
      </div>
      <div class="save" @click="setCookie(); hideModal()">
        Save my settings
      </div>
    </modal>
  </div>

</template>

<script>
import Switcher from '@/components/Switcher.vue'
export default {
name: "Cookies",
  components:{
    Switcher
  },
  data () {
    return {
      show_sign: false,
      pers_exp: true
    }
  },
  created(){
    if(!this.$cookies.get("accckc") ){
      this.show_sign = true
    }
  },
  methods:{
    openedModal(){
      document.body.classList.add('stack')
    },
    closedModal(){
      document.body.classList.remove('stack')
    },
    hideModal () {
      this.$modal.hide('CookieModal');
    },
    setCookie(){
      console.log('asd')
      let date = new Date;
      date.setDate(date.getDate() + 30);
      this.$cookies.set("accckc",`${new Date}_pers_exp_${this.pers_exp}`, date);
      this.show_sign = false
    }
  }
}
</script>
<style>
.stack{
  overflow: hidden;
  position:fixed;
  width: 100%;
}
.CookieModal{
  top:  calc(50% - 320px)!important;
  left: calc(50% - 350px)!important;
  width: 696px!important;
  height: 640px!important;
}
@media screen and (max-width: 420px) {
  .CookieModal{
    width: calc(100% - 32px)!important;
    height: calc(100% - 120px)!important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 80px;
    position: sticky!important;
  }
}
</style>
<style scoped>
.wrap::-webkit-scrollbar {
  width: 1px;
  display: none;
}

.wrap::-webkit-scrollbar-track {
background-color: #e4e4e4;
border-radius: 10px;
  display: none;
}

.wrap::-webkit-scrollbar-thumb {
border-radius: 10px;
border-left: 0;
border-right: 0;
background-color: #FFC869;
  display: none;
}
.mb {
  margin-bottom: 40px;
}
.headr{
  padding: 24px 24px 0px 24px
}
.save{
  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFC869;
  cursor: pointer;
}
.flex-descr{
  display: flex;
  justify-content: space-between;
}
.devider{
  margin-top: 32px;
  margin-bottom: 24px;
}
.wrap{
  padding: 0 24px 0px 24px;
  height: calc(100% - 168px);
  overflow: auto;
}
.descr{
  margin-top: 8px;
  color: #FFFFFF;
  font-family: "Helvetica Neue regular";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
.title-descr{
  font-family: 'Helvetica Neue Medium';
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.pps{
  height: 39px;
  width: 419px;
  color: #FFC869;
  font-family: 'Helvetica Neue regular';
  font-size: 34px;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 24px;
}
.modal__close{
  margin-top: -8px;
  margin-right: -8px;
  float: right;
  cursor: pointer;
}
.cls-btn{
  width: 20px;
  height: 20px;
  background: url('../assets/icn_menu_close.svg');
}
.cls-btn:hover{
  width: 20px;
  height: 20px;
  background: url('../assets/icn_menu_close2.svg');
}
.yellow{
  color: #FFC869;
}
.icon-icn_menu_close1:hover{
  fill: #FFC869;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
.btns{
  display: flex;
  align-items: center;
}
.menu__btn:focus {
  outline: none;
  box-shadow: none;
}
.cookie-settings {
  color: #FFC869;
  font-family: 'Helvetica Neue Medium';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin: 24px;
  cursor: pointer;
}
.cookie-settings:hover{
  color: #f4a010;
}
.menu__btn {
  width: 130px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.texts{
  width: 600px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 16px;
}
.title{
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  font-family: 'Helvetica Neue Bold';
  margin-bottom: 4px;
}
.descr{
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  font-family: 'Helvetica Neue Regular';
}
.overlay{
  position: fixed;
  height: calc(100% - 120px);
  width: 100%;
  background: #1b202370;
  z-index: 99;
}
.info{
  position: fixed;
  bottom:0;
  height: 120px;
  width: 100%;
  background: #07878B;
  z-index: 999;
}
.wrapper{
  padding: 18px 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .btns{
    flex-direction: row-reverse;
  }
  .wrapper{
    flex-direction: column;
    padding: 24px;
    align-items: flex-start
  }
  .overlay{
    height: calc(100% - 189px);
  }
  .info{
    height: 189px;
  }
}
@media screen and (max-width: 420px) {
  .overlay{
    height: calc(100% - 200px);
  }
  .info{
    height: 200px;
  }
  .texts{
    width: auto;
  }
  .wrapper{
    padding: 8px 16px 16px;
  }
  .title{
    font-size: 18px;
    line-height: 28px;
    font-family: 'Helvetica Neue Medium';
  }
.pps{
  height: 56px;
  width: 211px;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
}
.title-descr{
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}
.wrap{
  padding: 0 16px 0px 16px;
}
.descr{
  margin-top: 4px;
}
  .devider {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .colmn{
    flex-direction: column;
  }
  .save{
    height:  64px
  }
}
</style>