<template>
  <div class="avl">
    <div class="chapter-title-added av-itm">Availability</div>
    <div class="availible">
      <div class="availible__item">
        <div class="av-content">
          <Slider :img="studioImages" class="slide" />
          <!--        <img src="@/assets/brookfield_web/plan_1.jpg" alt="" class="availible__plan">-->
          <div class="availible__title">
            <div class="availible__name">Studio</div>
            <div class="availible__cost">$2,525</div>
          </div>
          <Table
              v-bind:tableData="tableStudioData"
          />
        </div>
      </div>
      <div class="availible__item">
        <div class="av-content">
          <Slider :img="oneImages" class="slide" />
  <!--        <img src="@/assets/brookfield_web/plan_2.jpg" alt="" class="availible__plan">-->
          <div class="availible__title">
            <div class="availible__name">One-Bedroom</div>
            <div class="availible__cost">from $2,825</div>
          </div>
          <Table class="one-bd"
              v-bind:tableData="tableOneBedroomData"
          />
        </div>
      </div>
      <div class="availible__item">
        <div class="av-content">
          <Slider :img="twoImages" class="slide" />
  <!--        <img src="@/assets/brookfield_web/plan_3.jpg" alt="" class="availible__plan">-->
          <div class="availible__title">
            <div class="availible__name">
              <div>One-Bedroom (WIC and/or Kitchen Island and/or Water view)</div>
            </div>
            <div class="availible__name"></div>
            <div class="availible__cost">$3,075</div>
          </div>
          <Table
              v-bind:tableData="tableTwoBedroomData"
          />
        </div>
      </div>
      <div class="availible__item">
        <div class="av-content">
          <Slider :img="twoImages2" class="slide" />
          <div class="availible__title">
            <div class="availible__name">
              <div>Two Bedroom One-Bathroom or Two-Bathroom</div>
            </div>
            <div class="availible__cost">$3,400</div>
          </div>
          <Table
              v-bind:tableData="tableThreeBedroomData"
          />
        </div>
      </div>
    </div>
</div>

</template>

<script>
import Slider from '@/components/SliderPLan.vue'
import Table from '@/components/Table.vue'

import st1 from '@/assets/plansNew/studio/Studio1.png'
import st2 from '@/assets/plansNew/studio/Studio2.png'
import st3 from '@/assets/plansNew/studio/Studio3.png'
import st4 from '@/assets/plansNew/studio/Studio4.png'

import one1 from '@/assets/plansNew/1bed/1bedroom1.png'
import one2 from '@/assets/plansNew/1bed/1bedroom2.png'
import one3 from '@/assets/plansNew/1bed/1bedroom3.png'

import two1 from '@/assets/plansNew/2bed/1bedroom1.png'
import two2 from '@/assets/plansNew/2bed/1bedroom2.png'

import two3 from '@/assets/plansNew/3bed/2bedroom1.png'
import two4 from '@/assets/plansNew/3bed/2bedroom2.png'

export default {
    name: 'Availibility',
  components: {
      Table, Slider
  },
  data () {
      return {
        studioImages:[st1, st2 ,st3, st4],
        oneImages:   [one1, one2, one3 ],
        twoImages:   [two1, two2],
        twoImages2:  [two3, two4],
        // twoImages:   [two1, two2, two3, two4],
          tableStudioData: [
              {id: 1, size: '1 person', min: '$86,571', max: '$141,310' , sf:457.12},
              {id: 2, size: '2 people', min: '$86,571', max: '$161,590'},
          ],
          tableOneBedroomData: [
              {id: 1, size: '1 person', min: '$96,857', max: '$141,310' , sf:569.43},
              {id: 2, size: '2 people', min: '$96,857', max: '$161,590'},
              {id: 3, size: '3 people', min: '$96,857', max: '$181,740'},
          ],
        tableTwoBedroomData: [
              {id: 1, size: '1 person', min: '$96,857', max: '$141,310' , sf:569.43},
              {id: 2, size: '2 people', min: '$96,857', max: '$161,590'},
              {id: 3, size: '3 people', min: '$96,857', max: '$181,740'},
          ],
          // tableTwoBedroomData: [
          //     {id: 1, size: '2 people', min: '$105,429', max: '$128,570' , sf:638.47},
          //     {id: 2, size: '3 people', min: '$105,429', max: '$146,900'},
          //     {id: 3, size: '4 people', min: '$105,429', max: '$165,230'},
          // ],
          // tableThreeBedroomData: [
          //     {id: 2, size: '3 people', min: '$116,571', max: '$146,900' , sf:843.33},
          //     {id: 3, size: '4 people', min: '$116,571', max: '$165,230'},
          //     {id: 4, size: '5 people', min: '$116,571', max: '$183,560'},
          //     {id: 5, size: '6 people', min: '$116,571', max: '$198,520'},
          // ],
        tableThreeBedroomData: [
              {id: 2, size: '2 people', min: '$116,571', max: '$161,590' , sf:843.33},
              {id: 3, size: '3 people', min: '$116,571', max: '$181,740'},
              {id: 4, size: '4 people', min: '$116,571', max: '$201,890'},
              {id: 5, size: '5 people', min: '$116,571', max: '$218,010'},
          ],
      }
    },
}
</script>

<style scoped>
.av-itm{
  padding: 0 220px;
}
.avl{
  position: relative;
  overflow: hidden;
  max-width: 1440px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
}
.sign-text{
  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 46px;
  margin: 80px 0 0 228px;
}
.av-content{
  width: 486px;
  margin: 0 auto;
}
.one-bd{
  margin-bottom: 64px;
}
.wrp{
  display: flex;
  position: relative;
  width: auto;
  height: 438px;
}
.availible{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 60px;
}
.availible__item{
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.availible__item:nth-child(2n+1){
    border-right: 1px solid var(--text-color);
}
.availible__item:nth-child(1), .availible__item:nth-child(2){
    border-bottom: 1px solid var(--text-color);
}
.availible__plan{
    width:  270px;
    margin: 56px auto 100px;
  display:block
}
.availible__title{
    font-family: "Helvetica Neue Medium";
    font-size: 34px;
    font-weight: 500;
    line-height: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1100px) {
    .av-itm {
      padding: 0px 40px;
    }
    .availible{
        margin-top: 30px;
        margin-bottom: 40px;
    }
    .availible__item{
        padding: 80px 90px;
    }
  .avl{
    position: relative;
    overflow: hidden;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 768px) {
  .one-bd{
    margin-bottom: 50px;
  }
  .availible{
    margin-top: 0;
    margin-bottom: 50px;
  }
  .av-content{
    width: auto;
  }
  .sign-text{
    margin: 80px 0 0 24px;
  }
  .availible__item{
       padding: 65px 79px 0;
      -ms-flex-preferred-size: 90%;
      flex-basis: 90%;
      margin: auto;
      border: none;
      border-bottom: 1px solid var(--text-color);
  }
  .availible__item:nth-child(n+1){
      border-right: none;
  }
  .availible__plan{
      margin-bottom: 64px;
  }
  .availible__item:last-child{
      border-bottom: none;
  }
}
@media screen and (max-width: 600px) {
      .wrp{
        width: auto;
        height: 358px;
      }
      .sign-text{
        margin: 56px 0 0 15px;
        font-size: 24px;
        line-height: 31px;
      }
    .availible{
      margin-top: 0;
      margin-bottom:32px;
      display: block;
      padding: 0 16px;
    }
    .availible__item{
        margin: 0 0 60px;
        padding: 0;
        border-bottom: none !important;
    }
    .availible__item:last-of-type{
      margin: 0 0 0;
  }
    .availible__plan{
        height: 200px;
        width:  200px;
        margin-bottom: 48px;
    }
}
@media screen and (max-width: 450px) {
  .one-bd{
    margin-bottom: 0;
  }
    .availible__title{
        font-size: 24px;
    }
  .avl{
    padding-top: 0;
  }
}
@media screen and (max-width: 320px) {
    .availible{
        margin-top: 0px;
    }
    .availible__item{
        padding: 56px 16px;
    }
    .availible__plan{
        height: 140px;
        width:  140px;
        margin-bottom: 44px;
    }
}
</style>
