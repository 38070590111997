<template>
  <div class="ch-b-wrap">
    <div class="ch-b" @click="$emit('getVal', 'below_80')">
      <input type="radio"
          id="below_80"
          name="contact"
          value="below_80">
      <label for="below_80">Under 80k</label>
    </div>
    <div class="ch-b" @click="$emit('getVal', '80-110')">
      <input type="radio"
             id="80-110"
             name="contact"
             value="80-110">
      <label for="80-110">$80k-$110k</label>  </div>
    <div class="ch-b" @click="$emit('getVal', 'above_110')">
      <input type="radio"
             id="above_110"
             name="contact"
             value="above_110">
      <label for="above_110">Above $110k</label>  </div>
  </div>
</template>

<script>
export default {
  name: "Checkbox"
}
</script>

<style scoped>
.ch-b{
  display: flex;
}
.ch-b-wrap{
  font-family: "Helvetica Neue Regular";
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-bottom: 20px;
}
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #FFFFFF;
  background: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateY(1px);
  margin-right: 12px;
  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #FFC869
}
input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"]:checked {
  border: 2px solid #FFC869;
}
input[type="radio"]:hover {
  border: 1px solid #FFC869;
}
@media screen and (max-width: 420px) {
  .ch-b-wrap{
  align-items: flex-start;
  flex-direction: column;
  }
  input[type="radio"] {
    margin-bottom: 20px;
  }
}
</style>